var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.order.orderId)?_c('v-card',{staticClass:"order-card"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Détails de la Commande - "+_vm._s(_vm.order.orderRef))])]),_c('v-divider'),_c('v-card-subtitle',[_c('strong',[_vm._v("Client :")]),_vm._v(" "+_vm._s(_vm.order.clientName || 'Non spécifié')+" ")]),_c('v-card-subtitle',[_c('strong',[_vm._v("Email Client:")]),_vm._v(" "+_vm._s(_vm.order.clientEmail || 'Non spécifié')+" ")]),_c('v-card-subtitle',[_c('strong',[_vm._v("Nombre d'articles :")]),_vm._v(" "+_vm._s(_vm.order.bookOrderResponseList.length)+" ")]),_c('v-card-subtitle',[_c('strong',[_vm._v("Adresse :")]),_vm._v(" "+_vm._s(_vm.order.orderAddress)+" ")]),_c('v-card-subtitle',[_c('strong',[_vm._v("Type de Paiement :")]),_vm._v(" "+_vm._s(_vm.order.orderPaymentType)+" ")]),_c('v-card-subtitle',{staticClass:"status-update-container"},[_c('strong',[_vm._v("Statut de Commande :")]),_c('div',{staticClass:"status-container"},[_c('span',{class:{'status-dot-orange': _vm.order.orderState === 'EN_COURS', 'status-dot-green': _vm.order.orderState === 'EXPEDIE'}}),_vm._v(" "+_vm._s(_vm.order.orderState)+" ")]),_c('v-select',{staticClass:"status-select",attrs:{"items":_vm.statuses,"label":"Statut de commande"},model:{value:(_vm.order.orderState),callback:function ($$v) {_vm.$set(_vm.order, "orderState", $$v)},expression:"order.orderState"}}),_c('v-btn',{staticClass:"update-btn",attrs:{"color":"blue darken-1"},on:{"click":_vm.updateOrder}},[_vm._v("Mettre à jour")])],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.bookHeaders,"items":_vm.order.bookOrderResponseList,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.imgUrl",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"order-image",attrs:{"src":item.imgUrl,"alt":"Order Image"}})]}},{key:"item.bookPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.bookPrice.toFixed(2))+"€ ")]}},{key:"item.bookQnt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.bookQnt)+" ")]}},{key:"item.bookTVA",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calculateTVAForBook(item).toFixed(2))+"€ ")]}},{key:"item.bookTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calculateTotalForBook(item).toFixed(2))+"€ ")]}}],null,false,966164612)})],1):_vm._e(),(_vm.order.orderId)?_c('v-card',{staticClass:"mt-4 summary-card"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Résumé de la Commande")])]),_c('v-divider'),_c('v-card-subtitle',[_c('strong',[_vm._v("Livraison:")]),_vm._v(" "+_vm._s(this.order.delivery ? this.order.delivery : 0)+" € ")]),_c('v-card-subtitle',[_c('strong',[_vm._v("Somme TVA (5.5%):")]),_vm._v(" "+_vm._s(_vm.calculateTVA().toFixed(2))+"€ ")]),_c('v-card-subtitle',[_c('strong',[_vm._v("Somme Total:")]),_vm._v(" "+_vm._s(_vm.calculateTotal().toFixed(2))+"€ ")])],1):_vm._e(),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"timeout":"3000"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" "),_c('v-btn',{attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Fermer")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }